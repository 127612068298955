<template>
  <transition name="fade">
    <section class="main-wrapper">
      <Header />
      <router-view/>
    </section>
  </transition>
</template>

<script>
import Header from '@/components/Header'

export default {
  name: 'personalLayout',
  components: { Header }
}
</script>
